import revive_payload_client_hE3vOiG29g from "/Users/haoli/Documents/code/web/ai-official-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_adaro67zadianx3cfqlwhdn6fa/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_RcO0K5ruXG from "/Users/haoli/Documents/code/web/ai-official-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_adaro67zadianx3cfqlwhdn6fa/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_6sil4U8rlP from "/Users/haoli/Documents/code/web/ai-official-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_adaro67zadianx3cfqlwhdn6fa/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_mCFTzIcFqS from "/Users/haoli/Documents/code/web/ai-official-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_adaro67zadianx3cfqlwhdn6fa/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_DpReR6esyM from "/Users/haoli/Documents/code/web/ai-official-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_adaro67zadianx3cfqlwhdn6fa/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_IT2Ub16OGu from "/Users/haoli/Documents/code/web/ai-official-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_adaro67zadianx3cfqlwhdn6fa/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_lZphsRActP from "/Users/haoli/Documents/code/web/ai-official-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_adaro67zadianx3cfqlwhdn6fa/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/Users/haoli/Documents/code/web/ai-official-website/.nuxt/components.plugin.mjs";
import prefetch_client_bWtQQsLFZ6 from "/Users/haoli/Documents/code/web/ai-official-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_adaro67zadianx3cfqlwhdn6fa/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import element_plus_teleports_plugin_h4Dmekbj62 from "/Users/haoli/Documents/code/web/ai-official-website/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/Users/haoli/Documents/code/web/ai-official-website/.nuxt/element-plus-injection.plugin.mjs";
export default [
  revive_payload_client_hE3vOiG29g,
  unhead_RcO0K5ruXG,
  router_6sil4U8rlP,
  payload_client_mCFTzIcFqS,
  navigation_repaint_client_DpReR6esyM,
  check_outdated_build_client_IT2Ub16OGu,
  chunk_reload_client_lZphsRActP,
  components_plugin_KR1HBZs4kY,
  prefetch_client_bWtQQsLFZ6,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby
]