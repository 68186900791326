import { default as aboutlHyiJKHRAlMeta } from "/Users/haoli/Documents/code/web/ai-official-website/src/pages/about.vue?macro=true";
import { default as _91id_93zzMifrDPbqMeta } from "/Users/haoli/Documents/code/web/ai-official-website/src/pages/cert/[id].vue?macro=true";
import { default as home4USDPdo7j9Meta } from "/Users/haoli/Documents/code/web/ai-official-website/src/pages/home.vue?macro=true";
import { default as indexvdh1iy1bDKMeta } from "/Users/haoli/Documents/code/web/ai-official-website/src/pages/index.vue?macro=true";
import { default as _91id_93KFu3xWvIwEMeta } from "/Users/haoli/Documents/code/web/ai-official-website/src/pages/news/[id].vue?macro=true";
import { default as indexIpgCOcN6l1Meta } from "/Users/haoli/Documents/code/web/ai-official-website/src/pages/news/index.vue?macro=true";
import { default as policy1wl5btIz2eMeta } from "/Users/haoli/Documents/code/web/ai-official-website/src/pages/policy.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/Users/haoli/Documents/code/web/ai-official-website/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "cert-id",
    path: "/cert/:id()",
    component: () => import("/Users/haoli/Documents/code/web/ai-official-website/src/pages/cert/[id].vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    component: () => import("/Users/haoli/Documents/code/web/ai-official-website/src/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexvdh1iy1bDKMeta || {},
    component: () => import("/Users/haoli/Documents/code/web/ai-official-website/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "news-id",
    path: "/news/:id()",
    component: () => import("/Users/haoli/Documents/code/web/ai-official-website/src/pages/news/[id].vue").then(m => m.default || m)
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/Users/haoli/Documents/code/web/ai-official-website/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "policy",
    path: "/policy",
    component: () => import("/Users/haoli/Documents/code/web/ai-official-website/src/pages/policy.vue").then(m => m.default || m)
  }
]